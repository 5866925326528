<template>
  <div class="table-container">
    <b-table :data="callbacks" :loading="loading" :per-page="1000" paginated pagination-size="is-small">
      <b-table-column field="index" label="Index" sortable v-slot="props">
        {{ props.index + 1 }}
      </b-table-column>

      <b-table-column field="id" label="ID" sortable visible v-slot="props">
        {{ props.row.id }}
      </b-table-column>

      <b-table-column field="name" label="Name" sortable v-slot="props">
        {{ props.row.name }}
      </b-table-column>

      <b-table-column field="enabled" label="Enabled" sortable v-slot="props">
        {{ props.row.enabled ? 'yes' : 'no' }}
      </b-table-column>

      <b-table-column field="actions" label="Actions" v-slot="props">
        <router-link :to="{ name: 'callback', params: { id: props.row.id } }">
          <b-button size="is-small">
            <b-icon icon="pencil" size="is-small" />
          </b-button>
        </router-link>
        <a v-on:click="showDeleteDialog(props.row)">
          <b-button size="is-small">
            <b-icon icon="trash-can" size="is-small" />
          </b-button>
        </a>
      </b-table-column>

      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>Nothing here.</p>
          </div>
        </section>
      </template>

      <template slot="bottom-left">
        <b-button tag="router-link" :to="{ name: 'callback-add' }" type="is-success" icon-left="plus-box"
          >Add Callback</b-button
        >

        <b-button v-on:click="fetch" type="is-info" icon-left="refresh" outlined style="margin-left: 100px"
          >Refresh data</b-button
        >
      </template>
    </b-table>

    <div class="modal is-active" v-if="itemForDelete">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Delete confirmation</p>
          <button class="delete" aria-label="close" v-on:click="closeDeleteDialog"></button>
        </header>
        <section class="modal-card-body">Do you really want to delete callback {{ itemForDelete.name }}?</section>
        <footer class="modal-card-foot">
          <button class="button is-danger" v-on:click="deleteItem">Delete</button>
          <button class="button" v-on:click="closeDeleteDialog">Cancel</button>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import Callback from '../models/Callback';

export default {
  name: 'callbacks',
  data() {
    return {
      callbacks: [],
      itemForDelete: null,
      loading: false,
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    async fetch() {
      this.loading = true;
      const { data } = await Callback.getAll(this.$route.params.groupId);
      this.callbacks = data;
      this.loading = false;
    },
    async deleteItem() {
      await Callback.delete(this.itemForDelete.id);
      this.fetch();
      this.closeDeleteDialog();
      this.$toast.success('callback was deleted');
    },
    showDeleteDialog(item) {
      this.itemForDelete = item;
    },
    closeDeleteDialog() {
      this.itemForDelete = null;
    },
  },
};
</script>
